import { DataAdapter } from 'src/app/shared/adapter/adapter';
import { WebshopMetrics as WebshopMetricsV1 } from '../model/webshop-metrics.model';
import { WebshopMetrics } from 'src/app/shared/models/metrics/v1/webshop-metrics.model';

export class WebshopMetricsAdapter
  implements DataAdapter<WebshopMetricsV1[], WebshopMetrics>
{
  transform(data: WebshopMetricsV1[]): WebshopMetrics {
    const [currentMetrics, previousMetrics] = data;

    if (!previousMetrics) {
      return {
        date: currentMetrics.date,
        serviceLevel: currentMetrics.serviceLevel,
        previousServiceLevel: null,
        serviceLevelDiff: null,
        inventoryValue: currentMetrics.inventoryValue,
        turnoverTime: currentMetrics.turnoverTime,
        deadstockValue: currentMetrics.deadstockValue,
        inFullLevel: this._calculatePercentage(currentMetrics.inFullLevel),
        previousInFullLevel: null,
        inFullLevelDiff: null,
        onTimeLevel: this._calculatePercentage(currentMetrics.onTimeLevel),
        previousOnTimeLevel: null,
        onTimeLevelDiff: null,
        trend: currentMetrics.trend,
        seasonality: currentMetrics.seasonality,
        categoryAInventoryValue: currentMetrics.categoryAInventoryValue,
        categoryATurnoverTime: currentMetrics.categoryATurnoverTime,
        categoryBTurnoverTime: currentMetrics.categoryBTurnoverTime,
        categoryCTurnoverTime: currentMetrics.categoryCTurnoverTime,
        categoryBInventoryValue: currentMetrics.categoryBInventoryValue,
        categoryCInventoryValue: currentMetrics.categoryCInventoryValue,
        categoryATrend: currentMetrics.categoryATrend,
        categoryBTrend: currentMetrics.categoryBTrend,
        categoryCTrend: currentMetrics.categoryCTrend,
        categoryAServiceLevel: currentMetrics.categoryAServiceLevel,
        categoryBServiceLevel: currentMetrics.categoryBServiceLevel,
        categoryCServiceLevel: currentMetrics.categoryCServiceLevel,
        categoryADesiredServiceLevel:
          currentMetrics.categoryADesiredServiceLevel,
        categoryBDesiredServiceLevel:
          currentMetrics.categoryBDesiredServiceLevel,
        categoryCDesiredServiceLevel:
          currentMetrics.categoryCDesiredServiceLevel,
      };
    }

    const serviceLevelDiff = this._calculateDiff(
      currentMetrics.serviceLevel,
      previousMetrics.serviceLevel
    );

    const inFullLevelDiff = this._calculateDiff(
      currentMetrics.inFullLevel,
      previousMetrics.inFullLevel
    );

    const onTimeLevelDiff = this._calculateDiff(
      currentMetrics.onTimeLevel,
      previousMetrics.onTimeLevel
    );

    return {
      date: currentMetrics.date,
      serviceLevel: currentMetrics.serviceLevel,
      previousServiceLevel: previousMetrics.serviceLevel
        ? this._capValue(previousMetrics.serviceLevel)
        : null,
      serviceLevelDiff:
        serviceLevelDiff !== null ? this._capValue(serviceLevelDiff) : null,
      inventoryValue: currentMetrics.inventoryValue,
      turnoverTime: currentMetrics.turnoverTime,
      deadstockValue: currentMetrics.deadstockValue,
      inFullLevel: this._calculatePercentage(currentMetrics.inFullLevel),
      previousInFullLevel: this._calculatePercentage(
        previousMetrics.inFullLevel
      ),
      inFullLevelDiff: this._calculatePercentage(inFullLevelDiff),
      onTimeLevel: this._calculatePercentage(currentMetrics.onTimeLevel),
      previousOnTimeLevel: this._calculatePercentage(
        previousMetrics.onTimeLevel
      ),
      onTimeLevelDiff: this._calculatePercentage(onTimeLevelDiff),
      trend: currentMetrics.trend,
      seasonality: currentMetrics.seasonality,
      categoryAInventoryValue: currentMetrics.categoryAInventoryValue,
      categoryATurnoverTime: currentMetrics.categoryATurnoverTime,
      categoryBTurnoverTime: currentMetrics.categoryBTurnoverTime,
      categoryCTurnoverTime: currentMetrics.categoryCTurnoverTime,
      categoryBInventoryValue: currentMetrics.categoryBInventoryValue,
      categoryCInventoryValue: currentMetrics.categoryCInventoryValue,
      categoryATrend: currentMetrics.categoryATrend,
      categoryBTrend: currentMetrics.categoryBTrend,
      categoryCTrend: currentMetrics.categoryCTrend,
      categoryAServiceLevel: currentMetrics.categoryAServiceLevel,
      categoryBServiceLevel: currentMetrics.categoryBServiceLevel,
      categoryCServiceLevel: currentMetrics.categoryCServiceLevel,
      categoryADesiredServiceLevel: currentMetrics.categoryADesiredServiceLevel,
      categoryBDesiredServiceLevel: currentMetrics.categoryBDesiredServiceLevel,
      categoryCDesiredServiceLevel: currentMetrics.categoryCDesiredServiceLevel,
    };
  }

  private _calculateDiff(
    current: number | null,
    previous: number | null
  ): number | null {
    if (current === null || previous === null) return null;

    return current - previous;
  }

  private _calculatePercentage(value: number | null): number | null {
    if (value === null) return null;

    return this._capValue(value * 100);
  }

  private _capValue(value: number): number {
    return parseFloat(value.toFixed(2));
  }
}
