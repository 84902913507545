import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  Actions,
  ofActionDispatched,
  select,
  Select,
  Store,
} from '@ngxs/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoadDashboardCache } from 'src/app/core/actions/dashboard-cache.action';
import { WebshopSelected } from 'src/app/core/actions/webshop.action';
import { NOT_AVAILABLE_VALUE } from 'src/app/core/constants/global.constants';
import { DashboardCacheState } from 'src/app/core/states/dashboard-cache.state';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { DashboardDetails } from 'src/app/shared/models/stock-level/dashboard-cache.model';
import { Webshop } from 'src/app/shared/models/webshop/webshop.model';
import {
  ServiceLevelInfoUpdateCache,
  ServiceLevelInfoUpdateParameters,
} from './actions/service-level-info.action';
import { ServiceInfoRow } from './model/service-info-row.model';
import { ServiceLevelInfoState } from './state/service-level-info.state';
import { WebshopMetricsStateQueries } from '../webshop-metrics/state/webshop-metrics.queries';
import { WebshopMetrics } from 'src/app/shared/models/metrics/v1/webshop-metrics.model';

@Component({
  selector: 'app-service-level-info',
  templateUrl: './service-level-info.component.html',
  styleUrls: ['./service-level-info.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceLevelInfoComponent implements OnInit, OnDestroy {
  readonly NOT_AVAILABLE = NOT_AVAILABLE_VALUE;

  displayedColumns: string[] = [
    'name',
    'desiredServiceLevel',
    'currentServiceLevel',
    'inventoryValue',
    'stockTurnOver',
  ];

  dataSource$: Subject<ServiceInfoRow[]> = new BehaviorSubject<
    ServiceInfoRow[]
  >([]);

  webshop: Webshop = null;
  cache: DashboardDetails = null;

  private destroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private actions: Actions
  ) {}

  ngOnInit(): void {
    /**
     * Note: Data source must be emitted first always, otherwise
     * the component will not display the data properly.
     */
    this.store
      .select(ServiceLevelInfoState.serviceInfo)
      .pipe(takeUntil(this.destroy$))
      .subscribe(rows => {
        this.dataSource$.next(rows);
      });

    this.actions
      .pipe(ofActionDispatched(LoadDashboardCache), takeUntil(this.destroy$))
      .subscribe(() => {
        this.onCacheNull();
      });

    this.actions
      .pipe(ofActionDispatched(WebshopSelected), takeUntil(this.destroy$))
      .subscribe(() => {
        this.onWebshopNull();
      });

    this.store
      .select(WebshopState.selected)
      .pipe(takeUntil(this.destroy$))
      .subscribe((webshop: Webshop | null) => {
        this.onDataUpdated(webshop, this.cache);
      });

    this.store
      .select(DashboardCacheState.cache)
      .pipe(takeUntil(this.destroy$))
      .subscribe((cache: DashboardDetails) => {
        this.onDataUpdated(this.webshop, cache);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private onDataUpdated(
    webshop: Webshop | null,
    cache: DashboardDetails | null
  ): void {
    this.onWebshopUpdated(webshop);
    this.onCacheUpdated(cache);
  }

  /**
   * Called when the parameters are updated.
   * New events are emitted to let children components know
   * that there is new data available.
   */
  private onWebshopUpdated(webshop: Webshop | null): void {
    this.webshop = webshop;

    if (webshop == null) {
      return;
    }
  }

  /**
   * Called when the cache is updated.
   * New events are emitted to let children components know
   * that there is new data available.
   * @param cache the updated cache data.
   */
  private onCacheUpdated(cache: DashboardDetails | null): void {
    this.cache = cache;
    const metrics = this.store.selectSnapshot(
      WebshopMetricsStateQueries.metrics
    );

    if (cache == null) {
      return;
    }

    this.store.dispatch(
      new ServiceLevelInfoUpdateCache({
        index: 0,
        currentServiceLevel: metrics.categoryAServiceLevel,
        desiredLevel: metrics.categoryADesiredServiceLevel,
        inventoryValue: metrics.categoryAInventoryValue,
        inventoryTrendValue: metrics.categoryATrend,
        stockTurnOver: metrics.categoryATurnoverTime,
        revenue: cache.categoryA.revenue,
      })
    );

    this.store.dispatch(
      new ServiceLevelInfoUpdateCache({
        index: 1,
        currentServiceLevel: metrics.categoryBServiceLevel,
        desiredLevel: metrics.categoryBDesiredServiceLevel,
        inventoryValue: metrics.categoryBInventoryValue,
        inventoryTrendValue: metrics.categoryBTrend,
        stockTurnOver: metrics.categoryBInventoryValue,
        revenue: cache.categoryB.revenue,
      })
    );

    this.store.dispatch(
      new ServiceLevelInfoUpdateCache({
        index: 2,
        currentServiceLevel: metrics.categoryCServiceLevel,
        desiredLevel: metrics.categoryCDesiredServiceLevel,
        inventoryValue: metrics.categoryCInventoryValue,
        inventoryTrendValue: metrics.categoryCTrend,
        stockTurnOver: metrics.categoryCInventoryValue,
        revenue: cache.categoryC.revenue,
      })
    );
  }

  /**
   * Dispatches a new event for each service level type when
   * the cache has no values.
   */
  private onCacheNull(): void {
    for (let i = 0; i < 3; i++) {
      this.store.dispatch(
        new ServiceLevelInfoUpdateCache({
          index: i,
          currentServiceLevel: null,
          desiredLevel: null,
          inventoryValue: null,
          inventoryTrendValue: null,
          stockTurnOver: null,
          revenue: null,
        })
      );
    }
  }

  /**
   * Dispatches a new event for each service parameter type when
   * the cache has no values.
   */
  private onWebshopNull(): void {
    for (let i = 0; i < 3; i++) {
      this.store.dispatch(
        new ServiceLevelInfoUpdateParameters({ index: i, desiredLevel: null })
      );
    }
  }
}
