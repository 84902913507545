import { Injectable, inject } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';

import {
  ServiceLevelInfoUpdateCache,
  ServiceLevelInfoUpdateParameters,
} from '../actions/service-level-info.action';
import { ServiceInfoRow } from '../model/service-info-row.model';

export interface ServiceLevelInfoStateModel {
  serviceLevelInfo: ServiceInfoRow[];
}

@State<ServiceLevelInfoStateModel>({
  name: 'serviceLevelInfoState',
  defaults: {
    serviceLevelInfo: [
      {
        id: 0,
        name: 'A',
        color: '#27ae60',
        desiredServiceLevel: null,
        currentServiceLevel: null,
        inventoryValue: null,
        inventoryTrendValue: null,
        stockTurnOver: null,
        revenue: null,
      },
      {
        id: 1,
        name: 'B',
        color: '#156db2',
        desiredServiceLevel: null,
        currentServiceLevel: null,
        inventoryValue: null,
        inventoryTrendValue: null,
        stockTurnOver: null,
        revenue: null,
      },
      {
        id: 2,
        name: 'C',
        color: '#e74c3c',
        desiredServiceLevel: null,
        currentServiceLevel: null,
        inventoryValue: null,
        inventoryTrendValue: null,
        stockTurnOver: null,
        revenue: null,
      },
    ],
  },
})
@Injectable()
export class ServiceLevelInfoState {
  @Selector()
  static serviceInfo(state: ServiceLevelInfoStateModel): ServiceInfoRow[] {
    return state.serviceLevelInfo;
  }
  private store = inject(Store);

  @Action(ServiceLevelInfoUpdateParameters)
  updateParameters(
    ctx: StateContext<ServiceLevelInfoStateModel>,
    data: ServiceLevelInfoUpdateParameters
  ): void {
    ctx.setState(
      patch({
        serviceLevelInfo: updateItem(
          (item: ServiceInfoRow) => item.id === data.payload.index,
          patch({ desiredServiceLevel: data.payload.desiredLevel })
        ),
      })
    );
  }

  @Action(ServiceLevelInfoUpdateCache)
  updateDashboardCache(
    ctx: StateContext<ServiceLevelInfoStateModel>,
    data: ServiceLevelInfoUpdateCache
  ): void {
    ctx.setState(
      patch({
        serviceLevelInfo: updateItem(
          (item: ServiceInfoRow) => item.id === data.payload.index,
          patch({
            currentServiceLevel: data.payload.currentServiceLevel,
            desiredServiceLevel: data.payload.desiredLevel,
            inventoryValue: data.payload.inventoryValue,
            inventoryTrendValue: data.payload.inventoryTrendValue,
            stockTurnOver: data.payload.stockTurnOver,
            revenue: data.payload.revenue,
          })
        ),
      })
    );
  }
}
