import { WebshopMetricsStateModel } from '../state/webshop-metrics.state';

export const webshopMetricsDefaults: WebshopMetricsStateModel = {
  loading: true,
  date: null,
  serviceLevel: 0,
  previousServiceLevel: 0,
  serviceLevelDiff: 0,
  inventoryValue: 0,
  turnoverTime: 0,
  deadstockValue: 0,
  inFullLevel: null,
  previousInFullLevel: null,
  inFullLevelDiff: 0,
  onTimeLevel: null,
  previousOnTimeLevel: null,
  onTimeLevelDiff: 0,
  trend: null,
  seasonality: null,
  categoryAInventoryValue: 0,
  categoryATurnoverTime: 0,
  categoryBTurnoverTime: 0,
  categoryCTurnoverTime: 0,
  categoryBInventoryValue: 0,
  categoryCInventoryValue: 0,
  categoryATrend: 0,
  categoryBTrend: 0,
  categoryCTrend: 0,
  categoryAServiceLevel: 0,
  categoryBServiceLevel: 0,
  categoryCServiceLevel: 0,
  categoryADesiredServiceLevel: 0,
  categoryBDesiredServiceLevel: 0,
  categoryCDesiredServiceLevel: 0,
};
