import { Selector } from '@ngxs/store';
import {
  WebshopMetricsState,
  WebshopMetricsStateModel,
} from './webshop-metrics.state';
import { WebshopMetrics } from 'src/app/shared/models/metrics/v1/webshop-metrics.model';

export class WebshopMetricsStateQueries {
  @Selector([WebshopMetricsState])
  static loading(state: WebshopMetricsStateModel) {
    return state.loading;
  }

  @Selector([WebshopMetricsState])
  static metrics(state: WebshopMetricsStateModel): WebshopMetrics {
    return {
      date: state.date,
      serviceLevel: state.serviceLevel,
      previousServiceLevel: state.previousServiceLevel,
      serviceLevelDiff: state.serviceLevelDiff,
      inventoryValue: state.inventoryValue,
      turnoverTime: state.turnoverTime,
      deadstockValue: state.deadstockValue,
      inFullLevel: state.inFullLevel,
      previousInFullLevel: state.previousInFullLevel,
      inFullLevelDiff: state.inFullLevelDiff,
      onTimeLevel: state.onTimeLevel,
      previousOnTimeLevel: state.previousOnTimeLevel,
      onTimeLevelDiff: state.onTimeLevelDiff,
      seasonality: state.seasonality,
      trend: state.trend,
      categoryAInventoryValue: state.categoryAInventoryValue,
      categoryATurnoverTime: state.categoryATurnoverTime,
      categoryBTurnoverTime: state.categoryBTurnoverTime,
      categoryCTurnoverTime: state.categoryCTurnoverTime,
      categoryBInventoryValue: state.categoryBInventoryValue,
      categoryCInventoryValue: state.categoryCInventoryValue,
      categoryATrend: state.categoryATrend,
      categoryBTrend: state.categoryBTrend,
      categoryCTrend: state.categoryCTrend,
      categoryAServiceLevel: state.categoryAServiceLevel,
      categoryBServiceLevel: state.categoryBServiceLevel,
      categoryCServiceLevel: state.categoryCServiceLevel,
      categoryADesiredServiceLevel: state.categoryADesiredServiceLevel,
      categoryBDesiredServiceLevel: state.categoryBDesiredServiceLevel,
      categoryCDesiredServiceLevel: state.categoryCDesiredServiceLevel,
    };
  }

  @Selector([WebshopMetricsState])
  static deadStockValue(state: WebshopMetricsStateModel): number {
    return state.deadstockValue;
  }

  @Selector([WebshopMetricsState])
  static inventoryValue(state: WebshopMetricsStateModel): number {
    return state.inventoryValue;
  }
  @Selector([WebshopMetricsState])
  static trend(state: WebshopMetricsStateModel): number {
    return state.trend;
  }
  @Selector([WebshopMetricsState])
  static seasonality(state: WebshopMetricsStateModel): number {
    return state.seasonality;
  }
}
