import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from 'src/app/features/dashboard/dashboard.component';
import { HomeComponent } from 'src/app/features/home/home.component';
import { PageNotFoundComponent } from 'src/app/features/page-not-found/page-not-found.component';
import { SignInAuthenticatedComponent } from 'src/app/features/sso/components/sign-in-authenticated/sign-in-authenticated.component';
import { WebshopsComponent } from 'src/app/features/webshops/webshops.component';

import { SsoComponent } from '../../../features/sso/sso.component';
import { OAuth0Guard } from '../../guards/oauth0.guard';

import { ConfigResolver } from '../../resolvers/config.resolver';
import {
  SettingsLocaleResolver,
  SettingsResolver,
} from '../../resolvers/settings.resolver';
import { AccountResolver } from '../../resolvers/account.resolver';
import { WebshopResolver } from '../../resolvers/webshop.resolver';
import { featurePermissionsGuard } from '../../resolvers/feature-permissions.resolver';
import { AnalyticsComponent } from 'src/app/features/analytics/analytics.component';
import { AccountRolesResolver } from '../../resolvers/account-roles.resolver';
import { BASE_PATH } from '../../constants/global.constants';
import { RolesFeatureKeys } from '../../constants/roles.constants';
import { PermissionsRoutes } from 'src/app/shared/models/core.model';
import { environment } from 'src/environments/environment';

/**
 * Application routes.
 * Make sure that protected components have proper guards installed.
 */
export const appRoutes: PermissionsRoutes = [
  {
    path: '',
    resolve: { configuration: ConfigResolver },
    children: [
      { path: '', component: SsoComponent, pathMatch: 'full' },
      {
        path: 'login/authenticated',
        pathMatch: 'prefix',
        canActivate: [],
        component: SignInAuthenticatedComponent,
      },
      {
        path: 'webshops',
        pathMatch: 'prefix',
        canActivate: [OAuth0Guard],
        resolve: {
          account: AccountResolver,
          accountSettings: SettingsResolver,
          accountSettingsLocale: SettingsLocaleResolver,
        },
        component: WebshopsComponent,
      },
      {
        path: BASE_PATH + '/:webshopId',
        pathMatch: 'prefix',
        redirectTo: 'app/:webshopId/home',
      },
      {
        path: 'home/:webshopId',
        pathMatch: 'prefix',
        redirectTo: 'app/:webshopId/home',
      },
      {
        path: 'home/:webshopId/dashboard',
        pathMatch: 'prefix',
        redirectTo: 'app/:webshopId/home',
      },
      {
        path: BASE_PATH + '/:webshopId',
        pathMatch: 'prefix',
        canActivate: [OAuth0Guard],
        resolve: {
          webshops: WebshopResolver,
          account: AccountResolver,
          accountSettings: SettingsResolver,
          accountRoles: AccountRolesResolver,
        },
        component: HomeComponent,
        children: [
          {
            path: 'home',
            pathMatch: 'prefix',
            data: { routerName: 'home' },
            canActivate: [],
            component: DashboardComponent,
          },
          {
            path: 'analytics',
            pathMatch: 'prefix',
            data: {
              routerName: 'analytics',
              featureKey: RolesFeatureKeys.ANALYTICS,
            },
            resolve: {
              featurePermissions: featurePermissionsGuard,
            },
            component: AnalyticsComponent,
          },
          {
            path: 'purchase',
            pathMatch: 'prefix',
            data: {
              routerName: 'purchase',
              featureKey: RolesFeatureKeys.PURCHASE,
            },
            resolve: {
              featurePermissions: featurePermissionsGuard,
            },
            loadChildren: () =>
              import('../../../features/purchase-v3/purchase-v3.module').then(
                m => m.PurchaseV3Module
              ),
          },
          {
            path: 'purchase/editor',
            pathMatch: 'prefix',
            data: {
              routerName: 'purchase',
              featureKey: RolesFeatureKeys.PURCHASE,
            },
            resolve: {
              featurePermissions: featurePermissionsGuard,
            },
            loadChildren: () =>
              import('../../../features/purchase-v3/purchase-v3.module').then(
                m => m.PurchaseV3Module
              ),
          },
          {
            path: 'deliveries',
            pathMatch: 'prefix',
            data: {
              routerName: 'deliveries',
              featureKey: RolesFeatureKeys.DELIVERIES,
            },
            resolve: {
              featurePermissions: featurePermissionsGuard,
            },
            loadChildren: () =>
              import(
                '../../../features/deliveries-v2/deliveries-v2.module'
              ).then(m => m.DeliveriesV2Module),
          },
          {
            path: 'deliveries/editor',
            pathMatch: 'prefix',
            data: {
              routerName: 'deliveries',
              featureKey: RolesFeatureKeys.DELIVERIES,
            },
            resolve: {
              featurePermissions: featurePermissionsGuard,
            },
            loadChildren: () =>
              import(
                '../../../features/deliveries-v2/deliveries-v2.module'
              ).then(m => m.DeliveriesV2Module),
          },
          {
            path: 'products',
            pathMatch: 'prefix',
            data: {
              routerName: 'products',
              featureKey: RolesFeatureKeys.PRODUCTS,
            },
            resolve: {
              featurePermissions: featurePermissionsGuard,
            },
            loadChildren: () =>
              import('../../../features/products-v2/products-v2.module').then(
                m => m.ProductsV2Module
              ),
          },
          {
            path: 'suppliers',
            pathMatch: 'prefix',
            data: {
              routerName: 'suppliers',
              featureKey: RolesFeatureKeys.SUPPLIERS,
            },
            resolve: {
              featurePermissions: featurePermissionsGuard,
            },
            loadChildren: () =>
              import('../../../features/suppliers-v2/suppliers-v2.module').then(
                m => m.SuppliersV2Module
              ),
          },
          {
            path: 'settings',
            pathMatch: 'prefix',
            data: {
              routerName: 'settings',
              featureKey: RolesFeatureKeys.SETTINGS,
            },
            resolve: {
              featurePermissions: featurePermissionsGuard,
            },
            loadChildren: () =>
              import('../../../features/settings/settings.module').then(
                m => m.SettingsModule
              ),
          },
          {
            path: 'account',
            pathMatch: 'prefix',
            data: { routerName: 'account' },
            loadChildren: () =>
              import(
                '../../../features/user-settings/user-settings.module'
              ).then(m => m.UserSettingsModule),
          },
          {
            path: 'exports',
            pathMatch: 'prefix',
            data: { routerName: 'exports', featureKey: 'exports' },
            loadChildren: () =>
              import('../../../features/exports-v2/exports-v2.module').then(
                m => m.ExportsV2Module
              ),
          },
          {
            path: 'promotions',
            pathMatch: 'prefix',
            data: {
              routerName: 'promotions',
              featureKey: RolesFeatureKeys.PROMOTIONS,
            },
            resolve: {
              featurePermissions: featurePermissionsGuard,
            },
            loadChildren: () =>
              import(
                '../../../features/promotions-v2/promotions-v2.module'
              ).then(m => m.PromotionsV2Module),
          },
          {
            path: 'imports',
            pathMatch: 'prefix',
            data: { routerName: 'imports', featureKey: 'imports' },
            loadChildren: () =>
              import('../../../features/imports/imports.module').then(
                m => m.ImportsModule
              ),
          },
          {
            path: 'assembly',
            pathMatch: 'prefix',
            data: {
              routerName: 'assembly',
              featureKey: RolesFeatureKeys.ASSEMBLY,
              anyOf: false,
            },
            resolve: {
              featurePermissions: featurePermissionsGuard,
            },
            loadChildren: () =>
              import(
                '../../../features/production-orders/production-orders.module'
              ).then(m => m.ProductionOrdersModule),
          },
          {
            path: 'assembly/editor',
            pathMatch: 'prefix',
            data: {
              routerName: 'assembly',
              featureKey: RolesFeatureKeys.ASSEMBLY,
              anyOf: false,
            },
            resolve: {
              featurePermissions: featurePermissionsGuard,
            },
            loadChildren: () =>
              import(
                '../../../features/production-orders/production-orders.module'
              ).then(m => m.ProductionOrdersModule),
          },
        ],
      },
    ],
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes, {
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
  providers: [ConfigResolver, SettingsResolver, SettingsLocaleResolver],
})
export class AppRoutingModule {}
