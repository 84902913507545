<app-basic-context-nav-bar [titleName]="STRINGS.title" />

<div class="dashboard-master-container">
  <app-warning-banner
    icon="info-rounded"
    type="info"
    [description]="STRINGS.warning" />

  <div>
    <app-general-info></app-general-info>
    <app-service-level-chart-plugin
      class="service-level-chart"></app-service-level-chart-plugin>

    <app-service-level-info></app-service-level-info>
  </div>
</div>
