import { Component, OnDestroy, OnInit } from '@angular/core';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import {
  NavigationEnded,
  NavigationStarted,
} from './core/actions/navigation.action';
import { AuthenticationService } from './core/services/authentication.service';
import { LocaleService } from './core/services/locale.service';
import { NavigationService } from './core/services/navigation.service';
import { EchartsService } from './core/services/theme/echarts.service';
import { WebshopState } from './core/states/webshop.state';
import { FetchExportsSettings } from './core/actions/buyorder-settings.actions';
import { PermissionQueries } from './core/states/permissions.queries';
import { RolesFeatureKeys } from './core/constants/roles.constants';
import { AccountState } from './core/states/account.state';
import { PendingChangesState } from './core/states/pending-changes.state';
import {
  AllowWebshopChange,
  PreventWebshopChange,
} from './core/actions/webshop.action';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit, OnDestroy {
  navigationBarHideState$: Subject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  private destroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private actions: Actions,
    private _navigationService: NavigationService,
    private _localeService: LocaleService,
    private _auth: AuthenticationService,
    private echartsService: EchartsService
  ) {}

  ngOnInit(): void {
    this.store
      .select(WebshopState.selected)
      .pipe(
        filter(Boolean),
        distinctUntilKeyChanged('uuid'),
        switchMap(() => this.store.select(AccountState.userUuid)),
        filter(Boolean),
        debounceTime(1000),
        switchMap(() =>
          this.store.select(
            PermissionQueries.hasPermission(RolesFeatureKeys.SETTINGS)
          )
        ),
        filter((hasPurchaseAccess: boolean) => hasPurchaseAccess),
        switchMap(() => this.store.dispatch(new FetchExportsSettings())),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.store
      .select(PendingChangesState.hasPendingChanges)
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((hasPendingChanges: boolean) => {
        if (hasPendingChanges)
          return this.store.dispatch(new PreventWebshopChange());

        this.store.dispatch(new AllowWebshopChange());
      });

    this.actions
      .pipe(ofActionDispatched(NavigationStarted), takeUntil(this.destroy$))
      .subscribe(_ => {
        this.navigationBarHideState$.next(false);
      });

    this.actions
      .pipe(
        ofActionDispatched(NavigationEnded),
        delay(1000),
        takeUntil(this.destroy$)
      )
      .subscribe(_ => {
        this.navigationBarHideState$.next(true);
      });
  }

  ngOnDestroy(): void {
    this.navigationBarHideState$.complete();

    this.destroy$.next();
    this.destroy$.complete();
  }
}
